// JS libraries
import "../scripts/frontend/setup_jquery.js";
import 'bootstrap';
import "popper.js/dist/umd/popper";

import Rails from 'rails-ujs';
Rails.start();

// Turbo setup
import "@hotwired/turbo-rails"
Turbo.session.drive = true

// Stimulus
import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import NestedForm from 'stimulus-rails-nested-form'
import Reveal from 'stimulus-reveal-controller'
import StarsController from '../scripts/frontend/stars_controller'

// modules
import { Fancybox } from "@fancyapps/ui";

// Judge js and dependencies
import "../scripts/vendor/judge/judge.js";

window.Location = Location;

const application = Application.start();
const context = require.context('../scripts/controllers', true, /\.js$/);
application.load(definitionsFromContext(context));

application.register('nested-form', NestedForm)
application.register('reveal', Reveal)
application.register('stars', StarsController)
